<template>
  <div class="user-transfer">
    <div class="title">Transfer</div>
    <b-form @submit.prevent="onTransfer()">
      <div class="form-group">
        <label for="FromAccount">From Account</label>
        <b-form-input
          v-model.trim="subAccount_from"
          required
          type="text"
          id="FromAccount"
          readonly
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="ToAccount">To Account</label>
        <b-form-input
          v-model.trim="transfer.user_id"
          required
          type="text"
          id="ToAccount"
          placeholder="Enter Receive ID"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="Balance">Balance</label>
        <b-form-input
          :value="TruncateToDecimals(balance.MainBalance, 4)"
          id="Balance"
          readonly
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="Min">Fee ( $ )</label>
        <b-form-input v-model.trim="Coin.symbol[0].FeeTransfer" readonly id="Min"></b-form-input>
      </div>
      <div class="form-group">
        <label for="Amount">Amount</label>
        <b-form-input
          v-model.trim="transfer.amount"
          required
          type="number"
          id="Amount"
          placeholder="Enter your amount"
        ></b-form-input>
      </div>
      <div class="form-group">
        <label for="authCode">Two Factor Authentication</label>
        <b-form-input
          v-model.trim="transfer.authCode"
          required
          type="text"
          id="authCode"
          placeholder="Enter your auth Code"
        ></b-form-input>
      </div>
      <div class="form-group mt-2 d-flex justify-content-center align-items-center mb-0">
        <b-button variant="danger" class="mx-2" @click="closePopup()"> Cancel </b-button>
        <b-button
          variant="outline-success"
          class="mx-2 btn-egg"
          type="submit"
          :disabled="Coin.symbol[0].Transfer == 0 ? true : false"
          >Transfer</b-button
        >
      </div>
    </b-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    user: {
      type: String,
      default: null,
    },
    balance: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      transfer: {
        user_id: '',
        amount: '',
        authCode: '',
        token: null,
      },
      subAccount_from: '',
    };
  },
  computed: {
    ...mapGetters({
      Coin: 'wallet/Coin',
    }),
  },
  watch: {
    user: {
      handler() {
        this.subAccount_from = this.user;
      },
    },
  },
  mounted() {
    if (this.user) {
      this.subAccount_from = this.user;
    }
  },
  methods: {
    async onTransfer() {
      const token = await this.genarateCaptChaV3('wallet');
      if (!token) {
        this.$toastr.e('Too Fast! Please Wating Recaptcha.', 'Withdraw Failed');
      }
      this.transfer.token = token;
      this.$store.dispatch('wallet/req_postTransfer', this.transfer);
    },
    closePopup() {
      this.$emit('closePopup', true);
    },
  },
  created() {
    this.unsubscribe = this.$store.subscribe((mutation) => {
      switch (mutation.type) {
        case 'wallet/TRANSFER_SUCCESS':
          this.$emit('closePopup', true);
          this.transfer = {
            user_id: '',
            amount: '',
            authCode: '',
            token: null,
          };
          this.outLoad();
          break;
        default:
          break;
      }
    });
  },
  beforeDestroy() {
    this.unsubscribe();
  },
};
</script>

<style lang="scss">
.btn-outline-success:disabled {
  background: #009750 !important;
  cursor: not-allowed;
}

.user-transfer {
  min-width: 450px;
  margin: auto;
  width: 100%;
  padding: 1em;
  background-color: #0c83e9;
  .title {
    font-weight: 600;
    font-size: clamp(16px, 2vw, 22px);
    color: #ffffff;
    font-weight: bold;
    text-align: center;
    padding: 0 0 15px;
    border-bottom: 3px solid rgba($color: #000000, $alpha: 0.2);
    margin-bottom: 15px;
  }
  .form-group {
    label {
      font-weight: 600;
      color: #ffffff;
      font-size: 16px;
      margin-bottom: 0px;
    }
    select,
    input {
      border: 0;
      border-radius: 0;
      border-bottom: 2px solid #6c757d;
      height: 40px;
      font-size: clamp(15px, 1vw, 16.2px);
      font-weight: 600;
      &:focus {
        box-shadow: none;
        border-bottom: 2px solid #097501;
      }
      &::placeholder {
        font-weight: 500;
        font-size: clamp(13px, 1vw, 15px);
      }
    }
  }
}
</style>
